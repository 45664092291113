<template>
	<ion-page>
		<ion-content>
			<div class="ion-padding notch-margin-top">
				<ion-row class="a-header">
					<ion-col size="6">
						<div class="a-logo font-monoxil-bold text-bold">
							BEEN<br>THERE<br>TOGETHER
						</div>
					</ion-col>
					<ion-col size="6" style="text-align: right;">
						<div @click="closeFrame()">
							<img class="close-icon" src="../../assets/svg/close-icon.svg">
						</div>
					</ion-col>
				</ion-row>
				<div class="select-category ion-text-center text-bold">{{ $t("Select category") }}</div>
			</div>
      <template v-if="unplayedCategories?.length > 0">
        <ion-slides ref="slider" pager="true" :options="slideOpts" @ionSlideTransitionStart="setActiveCategory">
          <ion-slide v-for="(category, i) in unplayedCategories" :key="category.id" @click="navToCards(category.id)">
            <div class="custom-card ion-text-left position-relative" :style="`background-color: ${category.color};`">
              <ion-row>
                <ion-col size="6" class="ion-no-padding">
                  <div class="category-name text-medium">{{ $t("Category") }} {{`${i+1}`}}</div>
                </ion-col>
                <ion-col size="6" class="ion-no-padding">
                  <ion-row class="player ion-float-right">
                    <div class="ion-text-right text-medium">{{category.player_count}}</div>
                    <img class="svg" src="../../assets/svg/padestrian.svg">
                  </ion-row>
                </ion-col>
              </ion-row>
              <h1 class="font-monoxil text-bold">{{category.name}}</h1>
              <h5 class="text-medium position-absolute">{{category.description}}</h5>
            </div>
          </ion-slide>
        </ion-slides>
        <div class="ion-padding-horizontal">
          <div @click="navToCards(activeCategory.id)">
            <div style="text-decoration: none;">
              <ion-row class="ft4-5 ion-justify-content-center ion-align-items-center start-category font-monoxil text-bold" :style="`background: ${activeCategory?.color};`">
                {{ $t("Start") }} {{activeCategory?.name}}
              </ion-row>
            </div>
          </div>
        </div>
      </template>
		</ion-content>
	</ion-page>
</template>

<script>
import { IonSlides, IonSlide } from '@ionic/vue'
import { mapState} from 'vuex'
import { mapFields } from 'vuex-map-fields'

export default {
	components: { IonSlides, IonSlide },
	setup() {
		const slideOpts = {
			initialSlide: 0,
			speed: 400,
			slidesPerView: 1,
			centeredSlides: true,
			spaceBetween: window.innerWidth / -9,
		}
		return { slideOpts }
	},
	data(){
		return{
			activeCategory: {}
		}
	},

	async mounted() {
    if (this.$route.params.id > 0) {
      this.$store.commit('offline/activeCampaignUpdated', this.$route.params.id)
      await this.$store.dispatch('offline/loadCampaignCategories')
      this.$forceUpdate()
    }

    if (!this.activeCampaign)
      this.$router.push({name: 'Campaigns'})


    if (!this.unplayedCategories.length) this.$store.commit('game/allCategoriesFinished')
    this.activeCategory = this.unplayedCategories[0]
  },
	methods: {
		setActiveCategory(e){
			this.activeCategory = this.unplayedCategories[e.target.swiper.snapIndex]
		},
		async navToCards(categoryId){
			this.$router.push({name: 'Cards-ui', params:{categoryId: categoryId}})
		},
		closeFrame(){
			if (window.top.innerWidth >= 1024){
				this.frameEnabled = false,
				window.top.location.reload()
			} else {
				this.$router.push({name: 'Landing'})
			}
			console.log(this.frameEnabled)
		},
	},
	computed: {
    ...mapState('game', ['finishedCategories']),
		...mapState('offline', ['frameEnabled', 'categories', 'activeCampaign']),

		unplayedCategories(){
			return this.categories.filter(category => !this.finishedCategories.includes(`${category.id}`))
		}
	},
}

</script>

<style scoped lang="scss">

.close-icon {
	width: 10vw;
	height: 10vw;
	margin-top: 5vw;
}

.select-category {
	font-size: 4.5vw;
	padding-top: 5vw;
}

.start-category {
	border: 3px solid black;
	border-radius: 2.6vw;
	width: 85vw;
	padding: 5vw;
	font-size: 5vw;
	display: flex;
	margin-left: auto;
	margin-right: auto;
}

.category-name {
	font-size: 4.5vw;
	line-height: 7vw;
}

h1 {
	font-size: 8vw;
	margin-top: 5vw;
}

h5 {
	font-size: 4.5vw;
	padding-right: 3vw;
	bottom: 3vw;
}

.custom-card {
	min-height: 77vw;
	width: 85vw;
	padding: 3vw;
	border: 3px solid black;
	border-radius: 2.6vw;
	box-shadow: 0px 0px 10px rgb(145, 144, 144);
}

.player {

	svg {
		width: 8vw;
		height: 8vw;
	}

	div {
		font-size: 4.5vw;
		line-height: 7vw;
		margin-right: 1vw;
	}

}

ion-slide{
	margin: 5vw 0 15vw 0;
}

</style>
